import React from 'react';
import logo from 'assets/img/logo.png';
export const MainLayout = props => {
    const { children, hideFooter = false } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement("header", null,
            React.createElement("div", { className: "container-fluid bg-primary d-flex align-items-center" },
                React.createElement("div", { className: "container mx-auto d-block" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { id: "logo", className: "col-auto" },
                            React.createElement("img", { src: logo })))))),
        React.createElement("main", null,
            React.createElement("div", { className: "container mx-auto m-3 m-lg-5" }, children)),
        !hideFooter &&
            React.createElement("footer", null,
                React.createElement("div", { className: "container" },
                    React.createElement("p", null, "*Article L561-5, L561-6 et R561-12 du Code Mon\u00E9taire et Financier modifi\u00E9s par l'Ordonnance n\u00BA2016-1635 du 1er d\u00E9cembre 2016 et le D\u00E9cret n\u00BA2018-284 du 18 avril 2018")))));
};
