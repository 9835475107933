import { Spinner } from '@isyc-react/design/bootstrap';
import React from 'react';
import { useDocRequestContext } from '@isyc-react/form-file/modules/doc-management/deprecated/doc-request-v2/doc-request-context';
import { DocReqCardFile } from './doc-req-card-file';
export const DocumentalReqComponent = (props) => {
    const { docRequestList, onSuccess } = props;
    const { loading, error } = useDocRequestContext();
    console.log(docRequestList);
    return (React.createElement(React.Fragment, null, loading ?
        React.createElement("div", { className: 'd-flex justify-content-center align-items-center mb-4', style: { height: '750px' } },
            React.createElement(Spinner, { animation: 'border' }))
        :
            React.createElement(React.Fragment, null, docRequestList.map((doc, i) => (doc.requests.map((request, r) => (React.createElement(DocReqCardFile, { reqIndex: r, docIndex: i, key: request.ID_Document_Request, onSuccess: () => onSuccess(), request: request, maxSize: 10000000 }))))))));
};
