import { gql, useMutation } from "@apollo/client";
import { ADDRESS_FIELDS } from "common/apollo";
export const UPDATE_MAIN_ADDRESS = gql `
  ${ADDRESS_FIELDS}
    mutation updateMainAddressBforBank ($address:AddressDtoInput, $personId:Long!){
        person_updateMainAddressBforBank(address:$address,personId:$personId){
        ...AddressFields 
        }
    }    
`;
export const useMutationAddress = (options) => {
    return useMutation(UPDATE_MAIN_ADDRESS, Object.assign({ errorPolicy: 'all' }, options));
};
