import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languages, namespaces } from './i18n.constants';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { preloadNamespaces } from './i18n-preload';
const defaultLanguage = languages.fr;
export const createI18n = (language) => {
    i18next
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
        load: 'languageOnly',
        fallbackLng: language !== null && language !== void 0 ? language : language,
        lowerCaseLng: true,
        ns: [...preloadNamespaces, namespaces.common],
        backend: {
            loadPath: `${process.env.NODE_ENV === 'production' && process.env.public_path_resources
                ? process.env.public_path_resources
                : '/'}locales/{{lng}}/{{ns}}.json`,
        },
    });
};
