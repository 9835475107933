export const householdIncomeEnumCombo = [
    { value: "E100", label: "0 - 100 000 Euros" },
    { value: "E200", label: "100 000 - 300 000 Euros" },
    { value: "E300", label: "300 000 - 500 000 Euros" },
    { value: "E400", label: "500 000 - 1 000 000 Euros" },
    { value: "E500", label: "1 000 000 - 2 000 000 Euros" },
    { value: "E600", label: "2 000 000 - 5 000 000 Euros" },
    { value: "E700", label: "5 000 000 - 10 000 000 Euros" },
    { value: "E800", label: "> 10 000 000 Euros" },
];
