import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient } from '@isyc-react/oc-api';
import { ApolloMockLink } from '@isyc-react/oc-api/mocks/apollo/';
import { MockedProvider } from '@apollo/client/testing';
import { AuthProvider } from '@isyc-react/auth/modules/context';
import { NotificationHubProvider } from '@isyc-react/form/modules/context/notification-hub';
import { Router } from 'core/router';
import { createI18n } from './i18n/i18n';
import '@isyc-react/design/main';
import 'assets/scss/portal.scss';
import 'core/theme/variables.scss';
const SuspenseRouter = () => {
    createI18n();
    return (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'd-flex align-items-center justify-content-center h-100' },
                React.createElement("div", { className: 'spinner-border text-secondary', role: 'status', style: { width: '40px', height: '40px' } },
                    React.createElement("span", { className: 'visually-hidden' }, "Loading...")))) },
        React.createElement(AuthProvider, { checkLoggedIn: true },
            React.createElement(NotificationHubProvider, null,
                React.createElement(Router, null)))));
};
const App = () => {
    if (process.env.NODE_ENV === 'development' && process.env.mocks === 'true') {
        return (React.createElement(MockedProvider, { link: ApolloMockLink() },
            React.createElement(SuspenseRouter, null)));
    }
    return (React.createElement(ApolloProvider, { client: ApolloClient },
        React.createElement(SuspenseRouter, null)));
};
ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
