import { gql, useQuery } from '@apollo/client';
import { TAX_RESIDENT_FIELDS } from '../apollo';
// FIXME THIS QUERY ALREADY IS IN OC-API LIB -> use it from there
export const GET_TAX_RESIDENT_READ = gql `
  ${TAX_RESIDENT_FIELDS}
  query getTaxResidentDtosByParticipanId($participantId: Long!) {
    get_tax_resident_by_participant_id(participantId: $participantId) {
      ...TaxResidentFields
    }
  }
`;
export const useQueryTaxResident = (options) => {
    return useQuery(GET_TAX_RESIDENT_READ, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first' }, options));
};
