export const isStatusDossierValid = (dossier) => {
    switch (dossier.statusTreeLevel1) {
        case "call":
        case "firstContact":
        case "relaunch":
            switch (dossier.statusTreeLevel2) {
                case "mailOK":
                case "smsOK":
                case "smsMailOK":
                case "mail":
                case "sms":
                case "smsMail":
                    return true;
                default:
                    return false;
            }
        default:
            return false;
    }
};
