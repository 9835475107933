import React from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { switchRoutes } from 'core/router/router';
import { MainScene } from 'scenes/example/main.scene';
import { EndScene, ErrorScene, WelcomeScene } from 'scenes';
import { AutoLoginLayout, AutoLoginLoggedForm } from '@isyc-react/auth/modules/pods/login';
const LoggedRouterRedirect = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Redirect, { to: switchRoutes.root })));
};
export const RouterLogged = () => {
    const location = useLocation();
    return (React.createElement(Switch, { location: location },
        React.createElement(Route, { path: [switchRoutes.autologin] },
            React.createElement(AutoLoginLayout, null,
                React.createElement(AutoLoginLoggedForm, null))),
        React.createElement(Route, { exact: true, path: [switchRoutes.end] },
            React.createElement(EndScene, null)),
        React.createElement(Route, { exact: true, path: [switchRoutes.information] },
            React.createElement(MainScene, null)),
        React.createElement(Route, { exact: true, path: switchRoutes.error },
            React.createElement(ErrorScene, null)),
        React.createElement(Route, { path: [switchRoutes.root] },
            React.createElement(WelcomeScene, null)),
        React.createElement(Route, { path: '*' },
            React.createElement(Redirect, { to: switchRoutes.root }))));
};
