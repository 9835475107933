import { useAuthContext } from '@isyc-react/auth/modules/context';
import { Spinner } from '@isyc-react/design/bootstrap';
import { useDossierReadByPersonQuery } from '@isyc-react/oc-api';
import { useMutationDossierStatus } from 'common/queries/mutation-dossierStatus';
import { useQueryErrorBforBank } from 'common/queries/query-errorBforBank';
import { isStatusDossierValid } from 'common/utils';
import { switchRoutes } from 'core/router/router';
import { namespaces } from 'i18n/i18n.constants';
import { MainAddressComponent, PersonalInformationsComponent, ResidenceFiscaleComponent, ContactComponent } from 'pods/person';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
export const MainComponent = () => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation(namespaces.common);
    const { user } = useAuthContext();
    const history = useHistory();
    const [disabled, setDisabled] = React.useState(true);
    const { data: dossiersData, loading } = useDossierReadByPersonQuery({ variables: { personId: (_b = (_a = user === null || user === void 0 ? void 0 : user.person) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0 } });
    const [mutationDossierStatus, { loading: loadingDossierStatus }] = useMutationDossierStatus();
    const dossier = React.useMemo(() => { var _a; return (_a = dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson[0]) !== null && _a !== void 0 ? _a : null; }, [dossiersData]);
    const { data: errorData, refetch } = useQueryErrorBforBank({ variables: { participantId: (_c = dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson[0].participants.filter((p => { var _a; return p.person.id === ((_a = user === null || user === void 0 ? void 0 : user.person) === null || _a === void 0 ? void 0 : _a.id); }))[0].id) !== null && _c !== void 0 ? _c : 0 } });
    React.useEffect(() => {
        (errorData === null || errorData === void 0 ? void 0 : errorData.is_valid) && errorData.is_valid.length == 0 ? setDisabled(false) : setDisabled(true);
    }), [errorData];
    return (React.createElement(React.Fragment, null, loading ?
        React.createElement("div", { className: 'd-flex align-items-center justify-content-center' },
            React.createElement(Spinner, { animation: 'border' }))
        :
            React.createElement(React.Fragment, null, (user === null || user === void 0 ? void 0 : user.person) && dossier && isStatusDossierValid(dossier) ?
                React.createElement(React.Fragment, null, errorData && errorData.is_valid &&
                    React.createElement(React.Fragment, null,
                        React.createElement("h1", null, "R\u00E9capitulatif"),
                        React.createElement(MainAddressComponent, { errorDtos: errorData.is_valid.filter((f) => f.step === 1), validator: () => refetch(), participantId: (_d = dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson[0].participants.filter((p => { var _a; return p.person.id === ((_a = user.person) === null || _a === void 0 ? void 0 : _a.id); }))[0].id) !== null && _d !== void 0 ? _d : 0 }),
                        React.createElement(ContactComponent, { errorDtos: errorData.is_valid.filter((f) => f.step === 4), validator: () => refetch() }),
                        (dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson[0].participants) &&
                            React.createElement(ResidenceFiscaleComponent, { errorDtos: errorData.is_valid.filter((f) => f.step === 2), validator: () => refetch(), participant: (_e = dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson[0].participants.filter((p => { var _a; return p.person.id === ((_a = user.person) === null || _a === void 0 ? void 0 : _a.id); }))[0]) !== null && _e !== void 0 ? _e : null }),
                        React.createElement(PersonalInformationsComponent, { errorDtos: errorData.is_valid.filter((f) => f.step === 3), validator: () => refetch() }),
                        React.createElement("div", { className: "d-flex align-items-end justify-content-end mt-3 mt-lg-5" },
                            React.createElement("button", { type: "button", className: "btn btn-secondary btn-lg btn-pill", disabled: loadingDossierStatus || disabled, onClick: () => {
                                    var _a;
                                    mutationDossierStatus({ variables: { dossierId: (_a = dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson[0].id) !== null && _a !== void 0 ? _a : 0 } }).then((data) => {
                                        var _a;
                                        ((_a = data.data) === null || _a === void 0 ? void 0 : _a.dossier_update_state) == true && history.push('/end');
                                    });
                                } }, "Valider"),
                            loadingDossierStatus &&
                                React.createElement("div", { className: 'd-flex align-items-center justify-content-center' },
                                    React.createElement(Spinner, { animation: 'border' })))))
                : React.createElement(Redirect, { to: switchRoutes.error }))));
};
