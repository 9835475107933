import { gql, useQuery } from '@apollo/client';
import { CONTACT_FIELDS } from '../apollo';
// FIXME THIS QUERY ALREADY IS IN OC-API LIB -> use it from there
export const GET_MAIN_CONTACT_READ = gql `
  ${CONTACT_FIELDS}
  query getMainContactRead($ownerId: Long!,$ownerTypeId: Int) {
    load_main_contact_by_owner_id(ownerId: $ownerId,ownerTypeId:$ownerTypeId) {
      ...ContactFields
    }
  }
`;
export const useQueryMainContact = (options) => {
    return useQuery(GET_MAIN_CONTACT_READ, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first' }, options));
};
