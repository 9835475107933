import { useAuthContext } from '@isyc-react/auth/modules/context';
import { Spinner } from '@isyc-react/design/bootstrap';
import { useDossierReadByPersonQuery } from '@isyc-react/oc-api';
import { isStatusDossierValid } from 'common/utils';
import { switchRoutes } from 'core/router/router';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
export const WelcomeComponent = () => {
    var _a, _b;
    const history = useHistory();
    const { user } = useAuthContext();
    const { data: dossiersData, loading } = useDossierReadByPersonQuery({ variables: { personId: (_b = (_a = user === null || user === void 0 ? void 0 : user.person) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0 } });
    const dossier = React.useMemo(() => { var _a; return (_a = dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson[0]) !== null && _a !== void 0 ? _a : null; }, [dossiersData]);
    return (React.createElement(React.Fragment, null, loading ?
        React.createElement("div", { className: 'd-flex align-items-center justify-content-center' },
            React.createElement(Spinner, { animation: 'border' }))
        :
            React.createElement(React.Fragment, null, (user === null || user === void 0 ? void 0 : user.person) && dossier && isStatusDossierValid(dossier) ?
                React.createElement(React.Fragment, null,
                    React.createElement("h1", null, "V\u00E9rification de vos informations personnelles"),
                    React.createElement("div", { className: "card p-4 bg-white rounded-lg shadow mt-3 mt-lg-5" },
                        React.createElement("div", { className: "card-body p-2" },
                            React.createElement("p", null, "La r\u00E9glementation bancaire en mati\u00E8re de pr\u00E9vention du blanchiment de capitaux et du financement du terrorisme impose \u00E0 chaque \u00E9tablissement bancaire une connaissance actualis\u00E9e de ses clients*. Ainsi, nous sommes tenus de recueillir r\u00E9guli\u00E8rement des informations vous concernant, concernant vos revenus et votre patrimoine."),
                            React.createElement("p", { className: "mb-0" }, "La date limite de v\u00E9rification de vos informations personnelles \u00E9tant d\u00E9pass\u00E9e, nous vous remercions d'acc\u00E9der au formulaire afin de mettre \u00E0 jour vos donn\u00E9es personnelles."))),
                    React.createElement("div", { className: "d-flex align-items-end justify-content-end mt-3 mt-lg-5" },
                        React.createElement("button", { type: "button", className: "btn btn-secondary btn-lg btn-pill", onClick: () => {
                                history.push('/information');
                            } }, "V\u00E9rifier mes informations")))
                : React.createElement(Redirect, { to: switchRoutes.error }))));
};
