import React from 'react';
import { useLocation } from 'react-router-dom';
import { AuthErrorCode, baseAutoLogin } from '@isyc-react/oc-api/auth';
import { useTranslation } from 'react-i18next';
import { nsauth } from '@isyc-react/auth/i18n/namespaces';
import { AutoLoginLoader } from '@isyc-react/auth/modules/pods/login/auto/loader';
const AutoLoginExpired = props => {
    const { error } = props;
    const { t } = useTranslation(nsauth.common);
    return (React.createElement("div", null,
        React.createElement("h2", { className: 'text-danger text-center' }, "L'URL de connexion automatique a expir\u00E9")));
};
export const AutoLoginError = props => {
    const { error } = props;
    const { code } = error;
    switch (code) {
        case AuthErrorCode.EXPIRED_URL:
            return React.createElement(AutoLoginExpired, { error: error });
        case AuthErrorCode.DECRYPT_PARAMS:
            return (React.createElement("div", null,
                React.createElement("h2", { className: 'text-danger text-center' }, "Erreur de d\u00E9chiffrement de l'URL de connexion automatique"),
                React.createElement("p", { className: 'text-center' }, "V\u00E9rifiez que l'url est correcte")));
        default:
            return React.createElement("h2", { className: 'text-danger text-center' }, "Erreur de connexion automatique");
    }
};
export const AutoLoginForm = () => {
    const { search } = useLocation();
    const [error, setError] = React.useState(null);
    const buCode = React.useMemo(() => {
        const params = new URLSearchParams(search);
        const buCodeParam = params.get('bu');
        return buCodeParam ? decodeURIComponent(buCodeParam) : 'SHARE';
    }, [search]);
    const p = React.useMemo(() => {
        const params = new URLSearchParams(search);
        return params.get('p');
    }, [search]);
    React.useEffect(() => {
        if (p && buCode) {
            baseAutoLogin({ bu: buCode, p })
                .then(res => {
                const { redirectUrl } = res.data;
                window.location.href = redirectUrl;
            })
                .catch((e) => {
                var _a, _b;
                setError((_b = (_a = e.response) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : { code: AuthErrorCode.GENERIC_ERROR });
            });
        }
        else {
            setError({ code: AuthErrorCode.DECRYPT_PARAMS });
        }
    }, [p, buCode]);
    return (React.createElement("div", { className: 'd-flex align-items-center justify-content-center' }, error ? (React.createElement(AutoLoginError, { error: error })) : (React.createElement("div", { className: 'd-flex align-items-center justify-content-center flex-column' },
        React.createElement(AutoLoginLoader, null),
        React.createElement("h2", { className: 'mt-3' }, "Connexion en cours")))));
};
