import { gql, useQuery } from '@apollo/client';
import { INFORMATION_PROFESSIONAL_FIELDS } from '../apollo';
// FIXME THIS QUERY ALREADY IS IN OC-API LIB -> use it from there
export const GET_INFORMATION_PROFESSIONAL_READ = gql `
  ${INFORMATION_PROFESSIONAL_FIELDS}
  query getInformationProfessionalByPersonId($personId: Long!) {
    load_information_professional_by_person_id(personId: $personId) {
      ...InformationProfessional
    }
  }
`;
export const useQueryInformationProfessional = (options) => {
    return useQuery(GET_INFORMATION_PROFESSIONAL_READ, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first' }, options));
};
