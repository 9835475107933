var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import size from 'pretty-bytes';
import clsx from 'clsx';
import { useDocRequestContext } from '@isyc-react/form-file/modules/doc-management/deprecated/doc-request-v2/doc-request-context';
import { BoopTransition } from '@isyc-react/animation/modules/base/transition';
import { DeprecatedDropFile } from '@isyc-react/form-file/modules/doc-management/deprecated/doc-request-v2/doc-drop-file';
import { uploadDocRequest } from '@isyc-react/form-file/modules/doc-management/deprecated/doc-request-v2/doc-request/doc-req-card-file.query';
import { Spinner } from '@isyc-react/design/bootstrap';
import produce from 'immer';
import { RippleButton } from '@isyc-react/form/modules/base';
const truncate = (value, limit = 25, completeWords = false, ellipsis = '...') => {
    if (completeWords)
        limit = value.substr(0, limit).lastIndexOf(' ');
    return value.length > limit && limit !== -1 ? value.substring(0, limit) + ellipsis : value;
};
var DocumentalRequestErrorCode;
(function (DocumentalRequestErrorCode) {
    DocumentalRequestErrorCode["GET_GENERIC"] = "GET_GENERIC";
    DocumentalRequestErrorCode["NO_OPTION_SELECTED"] = "NO_OPTION_SELECTED";
    DocumentalRequestErrorCode["NO_ACCEPTED_FILE"] = "NO_ACCEPTED_FILE";
    DocumentalRequestErrorCode["REJECTED_FILE"] = "REJECTED_FILE";
    DocumentalRequestErrorCode["LOW_FILE_QUALITY"] = "LOW_IMAGE_QUALITY";
    DocumentalRequestErrorCode["DOCUMENT_EDM_ERROR"] = "DOCUMENT_EDM_ERROR";
    DocumentalRequestErrorCode["UPLOADING_ERROR"] = "UPLOADING_ERROR";
})(DocumentalRequestErrorCode || (DocumentalRequestErrorCode = {}));
const DocumentalValidationError = (props) => {
    switch (props.code) {
        case DocumentalRequestErrorCode.NO_OPTION_SELECTED:
            return React.createElement("small", { className: 'text-red' }, "Aucune option documentaire n'a \u00E9t\u00E9 s\u00E9lectionn\u00E9e");
        case DocumentalRequestErrorCode.NO_ACCEPTED_FILE:
            return React.createElement("small", { className: 'text-red' }, "Aucun fichier valide n'a \u00E9t\u00E9 s\u00E9lectionn\u00E9");
        case DocumentalRequestErrorCode.LOW_FILE_QUALITY:
            return React.createElement("small", { className: 'text-red' }, "Le fichier a \u00E9t\u00E9 rejet\u00E9 en raison d'une mauvaise qualit\u00E9 d'image");
        case DocumentalRequestErrorCode.DOCUMENT_EDM_ERROR:
            return React.createElement("small", { className: 'text-red' }, "An error has occurred");
        default:
            return React.createElement(React.Fragment, null);
    }
};
export const DocReqCardFile = props => {
    var _a;
    const { request, reqIndex, docIndex, onSuccess, minSize, maxSize, accept } = props;
    const { setDocRequestFrame, dossiers, dossierSelectedId } = useDocRequestContext();
    const pending = dossiers[dossierSelectedId].pending;
    const completed = dossiers[dossierSelectedId].completed;
    const [state, setState] = React.useState({
        errors: [],
        uploading: false,
        file: undefined,
        selected: undefined,
    });
    const documentalError = (remove, add) => {
        let newErrors = [...state.errors];
        let removed = false;
        remove.forEach(e => {
            let hadError = newErrors.indexOf(e);
            if (hadError !== -1) {
                removed = true;
                newErrors.splice(hadError, 1);
            }
        });
        let added = false;
        add.forEach(e => {
            let hadError = newErrors.indexOf(e);
            if (hadError === -1) {
                added = true;
                newErrors.push(e);
            }
        });
        return removed || added ? newErrors : state.errors;
    };
    const upload = () => __awaiter(void 0, void 0, void 0, function* () {
        if (state.file) {
            const file = state.file;
            const formData = new FormData();
            formData.append('file', state.file);
            const documentalId = state.selected ? `?documentalId=${state.selected}` : '';
            uploadDocRequest(request.ID_Document_Request, documentalId, formData)
                .then((response) => {
                // console.log('Y SE DEVUELVE: ', response)
                setDocRequestFrame(frame => produce(frame, draft => {
                    const pending = draft.dossiers[draft.dossierSelectedId].pending;
                    const i = reqIndex, j = docIndex;
                    let newPending = [...pending];
                    newPending[i].requests.splice(j, 1);
                    draft.dossiers[draft.dossierSelectedId].pending = newPending;
                }));
                onSuccess && onSuccess();
            })
                .catch((e) => {
                console.error('[Upload Error]: ', e.response, e);
                let errors = documentalError([], [DocumentalRequestErrorCode.UPLOADING_ERROR]);
                setState(state => (Object.assign(Object.assign({}, state), { uploading: false, errors })));
            });
        }
    });
    const onAccepted = (file) => {
        let errors = documentalError([
            DocumentalRequestErrorCode.NO_ACCEPTED_FILE,
            DocumentalRequestErrorCode.REJECTED_FILE,
            DocumentalRequestErrorCode.LOW_FILE_QUALITY,
            DocumentalRequestErrorCode.DOCUMENT_EDM_ERROR,
        ], []);
        setState(Object.assign(Object.assign({}, state), { errors, file }));
    };
    const onRemoveAccepted = () => {
        let errors = documentalError([DocumentalRequestErrorCode.LOW_FILE_QUALITY, DocumentalRequestErrorCode.DOCUMENT_EDM_ERROR], []);
        setState(Object.assign(Object.assign({}, state), { errors, file: undefined }));
    };
    const onRejected = () => {
        let errors = documentalError([DocumentalRequestErrorCode.LOW_FILE_QUALITY, DocumentalRequestErrorCode.DOCUMENT_EDM_ERROR], [DocumentalRequestErrorCode.REJECTED_FILE]);
        setState(Object.assign(Object.assign({}, state), { errors, file: undefined }));
    };
    const onRemoveRejected = () => {
        let errors = documentalError([DocumentalRequestErrorCode.REJECTED_FILE], []);
        setState(Object.assign(Object.assign({}, state), { errors }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DeprecatedDropFile, { className: 'card border border-primary border-dotted bg-light h-100 p-4 mb-0', minSize: minSize, maxSize: maxSize !== null && maxSize !== void 0 ? maxSize : request.spaceUploadSize, accept: accept !== null && accept !== void 0 ? accept : ['image/jpeg', 'image/png', 'image/tiff', 'image/x-tiff', 'application/pdf'], disabled: state.uploading || (request.typology && request.typology.length > 0 && state.selected === undefined), disabledSelect: state.uploading, showDropFileStatus: true, onAccepted: (file) => onAccepted(file), onRemoveAccepted: () => onRemoveAccepted(), onRejected: () => onRejected(), onRemoveRejected: () => onRemoveRejected(), options: request.typology
                ? request.typology.map(t => ({ value: t.ID_Document_type.toString(), label: t.name }))
                : undefined, onOptionSelected: option => {
                if (option) {
                    let errors = documentalError([DocumentalRequestErrorCode.NO_OPTION_SELECTED], []);
                    setState(Object.assign(Object.assign({}, state), { selected: parseInt(option.value), errors }));
                }
            }, bottomChildren: React.createElement(React.Fragment, null,
                state.file && !state.uploading && state.errors.length === 0 && (React.createElement("div", { className: 'd-flex justify-content-center mt-3' },
                    React.createElement(RippleButton, { className: 'btn btn-primary btn-lg', onClick: () => {
                            setState(state => (Object.assign(Object.assign({}, state), { uploading: true })));
                            upload();
                        }, disabled: state.uploading },
                        React.createElement("div", { className: 'd-flex align-items-center' },
                            React.createElement("div", null, "T\u00E9l\u00E9charger"),
                            state.uploading && React.createElement(Spinner, { animation: 'border', size: 'sm', className: 'ms-2' }))))),
                !state.file && !state.errors.length && (React.createElement("small", { className: 'text-center text-muted mb-0 mt-3 d-block' },
                    "Taille maximum: ",
                    size((_a = props.maxSize) !== null && _a !== void 0 ? _a : props.request.spaceUploadSize, { locale: 'fr' })))) },
            React.createElement("div", { className: clsx('d-flex align-items-center justify-content-center mb-4', {
                    'mb-2': props.request.typology && props.request.typology.length,
                    'justify-content-between': state.file || state.uploading || state.errors.length > 0,
                }), style: { minHeight: '40px' } },
                React.createElement("div", { className: clsx('m-1 text-center font-size-17 fw-bold', {
                        'text-red': state.errors.length,
                        'text-primary': !state.errors.length,
                    }) }, props.request.name),
                (state.file || state.uploading || state.errors.length > 0) && (React.createElement("div", { className: 'align-self-end' }, state.errors.length > 0 && (React.createElement(BoopTransition, { show: Boolean(state.errors.length), rotation: 45, timing: 100 },
                    React.createElement("i", { className: 'mdi mdi-close font-size-30 text-red' })))))),
            state.errors.map((error, j) => (React.createElement("p", { key: j },
                React.createElement(DocumentalValidationError, { code: error })))))));
};
