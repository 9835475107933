import { gql, useQuery } from '@apollo/client';
import { ERROR_BFORBANK_FIELDS } from '../apollo';
// FIXME THIS QUERY ALREADY IS IN OC-API LIB -> use it from there
export const GET_ERROR_BFORBANK_READ = gql `
  ${ERROR_BFORBANK_FIELDS}
  query getErrorBforBankDtosByParticipanId($participantId: Long!) {
    is_valid(participantId: $participantId) {
      ...ErrorBforBankFields
    }
  }
`;
export const useQueryErrorBforBank = (options) => {
    return useQuery(GET_ERROR_BFORBANK_READ, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first' }, options));
};
