import { ValidationGroupValid } from "@isyc-react/form-file/modules/doc-management/deprecated/doc-request-v2/doc-group/doc-group.vm";
import React from "react";
import { DocGroupCardFile } from '@isyc-react/form-file/modules/doc-management/deprecated/doc-request-v2/doc-group/doc-group-card-file.component';
import { useAuthContext } from "@isyc-react/auth/modules/context";
import { AddressModal } from "pods/person";
import { OverlayTrigger, Popover } from "@isyc-react/design/bootstrap";
export const DocumentalComponent = (props) => {
    var _a;
    const { dossierID, mainAddress, doc, onSuccess } = props;
    const { user } = useAuthContext();
    const DocGroupToCompletedAndPending = (doc, relatedId, relatedObjectTypeId) => {
        const res = {
            pending: [],
            completed: [],
        };
        doc.forEach(d => {
            if (d.documentalValidationLinesRequired.length > 0 && d.code == 'ADDRESS') {
                if (d.validation === ValidationGroupValid.ANY) {
                    const uploaded = d.documentalValidationLinesRequired.find(line => line.archives.length > 0);
                    if (uploaded) {
                        res.completed.push({
                            name: d.name,
                            code: d.code,
                            requests: [
                                {
                                    tId: uploaded.documentType.id,
                                    tName: uploaded.documentType.name,
                                    tCode: uploaded.documentType.code,
                                    status: uploaded.status,
                                    archives: uploaded.archives,
                                },
                            ],
                        });
                    }
                    else {
                        res.pending.push({
                            name: d.name,
                            code: d.code,
                            relatedId,
                            relatedObjectTypeId,
                            requests: [
                                {
                                    tId: 0,
                                    tName: d.name,
                                    tCode: d.code,
                                    options: d.documentalValidationLinesRequired.map(line => (Object.assign({}, line.documentType))),
                                },
                            ],
                        });
                    }
                }
                else {
                    const pendingReq = [];
                    const completedReq = [];
                    d.documentalValidationLinesRequired.forEach(dv => {
                        if (dv.archives.length > 0) {
                            completedReq.push({
                                tId: dv.documentType.id,
                                tName: dv.documentType.name,
                                tCode: dv.documentType.code,
                                status: dv.status,
                                archives: dv.archives,
                            });
                        }
                        else {
                            pendingReq.push({
                                tId: dv.documentType.id,
                                tName: dv.documentType.name,
                                tCode: dv.documentType.code,
                                validation: d.validation,
                                tFormat: 'SINGLE',
                                options: [],
                            });
                        }
                    });
                    if (pendingReq.length > 0)
                        res.pending.push({
                            name: d.name,
                            code: d.code,
                            relatedId,
                            relatedObjectTypeId,
                            requests: pendingReq,
                        });
                    if (completedReq.length > 0)
                        res.completed.push({
                            name: d.name,
                            code: d.code,
                            requests: completedReq,
                        });
                }
            }
        });
        return res;
    };
    let item = (DocGroupToCompletedAndPending(doc, dossierID, 1415));
    const popoverFocus = (React.createElement(Popover, { id: "popover-trigger-focus", title: "Pour les autres documents accept\u00E9es vous pouvez charger :" },
        React.createElement(Popover.Header, { as: "h3" }, "Pour les autres documents accept\u00E9es vous pouvez charger :"),
        React.createElement(Popover.Body, null,
            React.createElement("ul", { className: "list-group" },
                React.createElement("li", { className: "list-group-item border-0" }, "Taxe d'habitation de moins d'un an"),
                React.createElement("li", { className: "list-group-item border-0" }, "Attestation d'assurance habitation de moins de 3 mois"),
                React.createElement("li", { className: "list-group-item border-0" }, "Quittance de loyer (par un organisme reconnu : HLM/CIAS) de moins de 3 mois")))));
    return (React.createElement(React.Fragment, null, item && (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'card-footer bg-white border-0 d-flex justify-content-end' },
            React.createElement(AddressModal, { ownerId: user && user.person ? (_a = user.person) === null || _a === void 0 ? void 0 : _a.id : 0, mainAddress: mainAddress, refrest: () => onSuccess() })),
        item.pending.length > 0 ?
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "row mt-5 text-center" },
                    React.createElement("p", null,
                        "Vous avez chang\u00E9 d\u2019adresse, veuillez t\u00E9l\u00E9charger votre pi\u00E8ce justificative :",
                        React.createElement(OverlayTrigger, { trigger: "click", rootClose: true, placement: "right", overlay: popoverFocus },
                            React.createElement("i", { className: "mdi mdi-information text-secondary font-size-24 cursor-help ms-1 align-middle" }))),
                    React.createElement("div", { className: " mb-0 mb-5 col-md-6 mx-auto align-items-center justify-content-center p-4" },
                        React.createElement(DocGroupCardFile, { request: item.pending[0].requests[0], reqIndex: 0, docIndex: 0, relatedId: item.pending[0].relatedId, relatedObjectTypeId: item.pending[0].relatedObjectTypeId, businessUnitId: 5100, onSuccess: () => { onSuccess(); }, maxSize: 10000000 }))))
            :
                React.createElement(React.Fragment, null)))));
};
