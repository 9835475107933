import { Button, Modal, Spinner } from "@isyc-react/design/bootstrap";
import React from "react";
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { RhfInputText } from '@isyc-react/form/modules/input/text';
import { useForm } from '@isyc-react/form/modules/rhf';
import { RhfInputSelect } from "@isyc-react/form/modules/input/select";
import { getWealthEnum, getHouseholdIncomeEnum } from "common/types";
import { useMutationInformationProfessional } from "common/queries";
import { householdIncomeEnumCombo } from "common/combo/householdIncome";
import { wealthCombo } from "common/combo/wealth";
export const InformationsProfessionalModal = props => {
    const [show, setShow] = React.useState(false);
    const { info, personId, comboPersonLabor, comboCodeNaf, refrest, errorBforBank: errorDtos } = props;
    const { t } = useTranslation(namespaces.common);
    const [mutationInformationProfessional, { loading }] = useMutationInformationProfessional();
    const { control, handleSubmit, reset, trigger, clearErrors, setError, formState: { isDirty, isValid } } = useForm({
        mode: 'all',
        defaultValues: Object.assign(Object.assign({}, info), { cno: info.cno ? comboPersonLabor.find(c => c.value === info.cno.toString()) : null, wealth: info.wealth ? wealthCombo.find(c => c.value === info.wealth.toString()) : null, householdIncome: info.householdIncome ? householdIncomeEnumCombo.find(c => c.value === info.householdIncome.toString()) : null, activitySectorBforBank: info.activitySectorBforBank ? comboCodeNaf.find(c => c.value === info.activitySectorBforBank.toString()) : null }),
    });
    const onSuccess = values => {
        mutationInformationProfessional({
            variables: {
                Informations: Object.assign(Object.assign({}, values), { cno: values.cno ? parseInt(values.cno.value) : 0, activitySectorBforBank: values.activitySectorBforBank ? parseInt(values.activitySectorBforBank.value) : 0, wealth: values.wealth ? getWealthEnum(values.wealth.value) : "UNDEFINED" /* WealthEnum.UNDEFINED */, householdIncome: values.householdIncome ? getHouseholdIncomeEnum(values.householdIncome.value) : "UNDEFINED" /* HouseholdIncomeEnum.UNDEFINED */ }),
                personId: personId
            }
        }).then((data) => {
            var _a, _b, _c, _d, _e;
            setShow(false);
            reset(Object.assign(Object.assign({}, (_a = data.data) === null || _a === void 0 ? void 0 : _a.person_updateInformationProfessional), { cno: ((_b = data.data) === null || _b === void 0 ? void 0 : _b.person_updateInformationProfessional.cno) ? comboPersonLabor.find(c => { var _a; return c.value === ((_a = data.data) === null || _a === void 0 ? void 0 : _a.person_updateInformationProfessional.cno.toString()); }) : null, wealth: ((_c = data.data) === null || _c === void 0 ? void 0 : _c.person_updateInformationProfessional.wealth) ? wealthCombo.find(c => { var _a; return c.value === ((_a = data.data) === null || _a === void 0 ? void 0 : _a.person_updateInformationProfessional.wealth.toString()); }) : null, householdIncome: ((_d = data.data) === null || _d === void 0 ? void 0 : _d.person_updateInformationProfessional.householdIncome) ? householdIncomeEnumCombo.find(c => { var _a; return c.value === ((_a = data.data) === null || _a === void 0 ? void 0 : _a.person_updateInformationProfessional.householdIncome.toString()); }) : null, activitySectorBforBank: ((_e = data.data) === null || _e === void 0 ? void 0 : _e.person_updateInformationProfessional.activitySectorBforBank) ? comboCodeNaf.find(c => { var _a; return c.value === ((_a = data.data) === null || _a === void 0 ? void 0 : _a.person_updateInformationProfessional.activitySectorBforBank.toString()); }) : null }));
            clearErrors();
            refrest && refrest();
        });
    };
    const onError = value => {
        console.log("error");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "btn btn-tertiary btn-lg btn-pill", onClick: () => {
                setShow(true);
                errorDtos.forEach((e) => {
                    if (e.property === "cno") {
                        setError(e.property, { type: 'required', message: 'Champ obligatoire' });
                    }
                    else if (e.property === "wealth") {
                        setError(e.property, { type: 'required', message: 'Champ obligatoire' });
                    }
                    else if (e.property === "householdIncome") {
                        setError(e.property, { type: 'required', message: 'Champ obligatoire' });
                    }
                    else if (e.property === "activitySectorBforBank") {
                        setError("activitySectorBforBank", { type: 'required', message: 'Champ obligatoire' });
                    }
                    else if (e.property === "jobTitle") {
                        setError("jobTitle", { type: 'required', message: 'Champ obligatoire' });
                    }
                    else if (e.property === "workCompanyName") {
                        setError(e.property, { type: 'required', message: 'Champ obligatoire' });
                    }
                });
            } },
            " ",
            t('edit')),
        React.createElement(Modal, { size: "xl", centered: true, show: show, onShow: () => {
            }, onHide: () => { setShow(false); reset(); }, onClose: () => { reset(); setShow(false); } },
            React.createElement(Modal.Header, { closeButton: true, className: "bg-white border-0" },
                React.createElement(Modal.Title, { className: "modal-title text-primary h5" }, "Vos informations professionnelles")),
            React.createElement(Modal.Body, { className: "px-4 pt-3" },
                React.createElement("p", null, "Veuillez v\u00E9rifier, modifier et/ou compl\u00E9ter les informations ci-dessous."),
                React.createElement("p", { className: "error" }, "* Champs obligatoires"),
                React.createElement("form", { onSubmit: handleSubmit(onSuccess, onError) },
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("div", { className: 'col-lg-5 d-flex justify-content-lg-end align-items-center text-primary mb-2 mb-lg-0' },
                            "Situation professionnelle",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("div", { className: 'col-lg-6 text-primary' },
                            React.createElement(RhfInputSelect, { rhf: { control, name: 'cno', rules: {
                                        validate: {
                                            valid: (value) => !value || !(/[$%&/=€^+ç@]/g.test(value)),
                                        },
                                    } }, input: { options: comboPersonLabor, isSearchable: true }, customErrorMessage: error => {
                                    if (error.type === 'required')
                                        return 'Champ obligatoire';
                                    else if (error.type === 'valid')
                                        return 'Caractère non valide';
                                } }))),
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-5 d-flex justify-content-lg-end align-items-center text-primary mb-2 mb-lg-0' },
                            "Employeur",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement(RhfInputText, { rhf: { control, name: 'workCompanyName', rules: {} }, input: { maxLength: 35 }, transform: value => value.replace(/[^a-zA-Z0-9 !"#$%&'()*,_/<=>?@^~ ]/g, ''), customErrorMessage: error => {
                                    if (error.type === 'required')
                                        return 'Champ obligatoire';
                                    else if (error.type === 'valid')
                                        return 'Caractère non valide';
                                } }))),
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-5 d-flex justify-content-lg-end align-items-center text-primary mb-2 mb-lg-0' },
                            "Secteur d'activit\u00E9",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement(RhfInputSelect, { rhf: {
                                    control, name: 'activitySectorBforBank',
                                }, input: { options: comboCodeNaf, isSearchable: true }, customErrorMessage: "Champ obligatoire" }))),
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-5 d-flex justify-content-lg-end align-items-center text-primary mb-2 mb-lg-0' },
                            "Intitul\u00E9 du poste ",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement(RhfInputText, { rhf: { control, name: 'jobTitle', rules: {} }, transform: value => value.replace(/[^a-zA-Z0-9 !"#$%&'()*,_/:<=>?@[\]^~ ]/g, ''), input: { maxLength: 35 }, customErrorMessage: error => {
                                    if (error.type === 'required')
                                        return 'Champ obligatoire';
                                    else if (error.type === 'valid')
                                        return 'Caractère non valide';
                                } }))),
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-5 d-flex justify-content-lg-end align-items-center text-primary mb-2 mb-lg-0' },
                            "Revenus annuels net du foyer (en euros)",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement("div", { className: 'row' },
                                React.createElement("div", { className: "col" },
                                    React.createElement(RhfInputSelect, { rhf: { control, name: 'wealth' }, input: { options: wealthCombo, isSearchable: true }, customErrorMessage: "Champ obligatoire" }))))),
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-5 d-flex justify-content-lg-end align-items-center text-primary mb-2 mb-lg-0' },
                            "Patrimoine net global (en euros) ",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement("div", { className: 'row' },
                                React.createElement("div", { className: "col" },
                                    React.createElement(RhfInputSelect, { rhf: { control, name: 'householdIncome' }, input: { options: householdIncomeEnumCombo, isSearchable: true }, customErrorMessage: "Champ obligatoire" }))))),
                    React.createElement("div", { className: "modal-footer d-flex justify-content-between border-0" },
                        React.createElement("button", { className: "btn btn-tertiary btn-pill px-5", type: 'button', disabled: loading, onClick: () => { reset(); setShow(false); } }, "Fermer"),
                        React.createElement("button", { className: "btn btn-secondary btn-pill px-5", disabled: loading || !isValid || !isDirty, type: 'submit', onClick: () => { clearErrors(); } }, "Enregistrer"),
                        loading && React.createElement(Spinner, { animation: 'border', size: 'sm', className: 'text-primary ' })))))));
};
