import React from 'react';
import { useAuthContext } from "@isyc-react/auth/modules/context";
import { ContactModal } from './contact-modal.component';
import { useQueryMainContact } from 'common/queries/query-mainContact-read';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
export const ContactComponent = (props) => {
    var _a, _b, _c, _d;
    const { user } = useAuthContext();
    const { t } = useTranslation(namespaces.common);
    const [info, setInfo] = React.useState(null);
    const { data: contactData, refetch } = useQueryMainContact({ variables: { ownerId: (_b = (_a = user === null || user === void 0 ? void 0 : user.person) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0, ownerTypeId: 1022 } });
    React.useEffect(() => (contactData === null || contactData === void 0 ? void 0 : contactData.load_main_contact_by_owner_id) && setInfo(contactData.load_main_contact_by_owner_id), [contactData]);
    return (React.createElement(React.Fragment, null, info &&
        React.createElement("div", { className: "card p-4 bg-white rounded-lg shadow mt-3 mt-lg-5" },
            React.createElement("div", { className: "card-header bg-white border-0" },
                React.createElement("h2", null, "Vos coordonn\u00E9es")),
            React.createElement("div", { className: "card-body" },
                React.createElement("div", { className: "row mb-2 mb-md-1" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Veuillez saisir votre adresse e-mail :"),
                    props.errorDtos.filter((t) => t.property === 'email').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        :
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, contactData === null || contactData === void 0 ? void 0 : contactData.load_main_contact_by_owner_id.serviceId))),
            React.createElement("div", { className: "card-footer bg-white border-0 d-flex justify-content-end" },
                React.createElement(ContactModal, { errorBforBank: props.errorDtos, info: info, personId: (_d = (_c = user === null || user === void 0 ? void 0 : user.person) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : 0, refrest: () => { refetch(); props.validator(); } })))));
};
