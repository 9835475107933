import { gql, useMutation } from '@apollo/client';
import { TAX_RESIDENT_FIELDS } from "common/apollo";
// FIXME THIS QUERY ALREADY IS IN OC-API LIB -> use it from there
export const CREATE_TAX_RESIDENT = gql `
  ${TAX_RESIDENT_FIELDS}
  mutation createTaxResident($taxResident: [TaxResidentDtoInput], $participantId: Long! ) {
    create_tax_resident(taxResident: $taxResident , participantId: $participantId){
      ...TaxResidentFields
    }
  }
`;
export const useMutationTaxResident = (options) => {
    return useMutation(CREATE_TAX_RESIDENT, Object.assign({ errorPolicy: 'all' }, options));
};
