import { Button, Modal, Spinner } from "@isyc-react/design/bootstrap";
import React from "react";
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { RhfInputText } from '@isyc-react/form/modules/input/text';
import { useForm } from '@isyc-react/form/modules/rhf';
import { useMutationContact } from "common/queries";
import { string } from '@isyc-react/form/modules/validator/string';
export const ContactModal = props => {
    const [show, setShow] = React.useState(false);
    const { info, personId, refrest, errorBforBank: errorDtos } = props;
    const { t } = useTranslation(namespaces.common);
    const [mutationContact, { loading }] = useMutationContact();
    const { control, handleSubmit, reset, trigger, clearErrors, setError, formState: { isDirty, isValid } } = useForm({
        mode: 'all',
        defaultValues: Object.assign({}, info),
    });
    const onSuccess = values => {
        mutationContact({
            variables: {
                serviceId: values.serviceId,
                personId: personId
            }
        }).then((data) => {
            setShow(false);
            clearErrors();
            refrest && refrest();
        });
    };
    const onError = value => {
        console.log("error");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "btn btn-tertiary btn-lg btn-pill", onClick: () => {
                setShow(true);
                errorDtos.forEach((e) => {
                    if (e.property === "email") {
                        setError("serviceId", { type: 'required', message: 'Champ obligatoire' });
                    }
                });
            } },
            " ",
            t('edit')),
        React.createElement(Modal, { size: "xl", centered: true, show: show, onShow: () => {
            }, onHide: () => { setShow(false); reset(); }, onClose: () => { reset(); setShow(false); } },
            React.createElement(Modal.Header, { closeButton: true, className: "bg-white border-0" },
                React.createElement(Modal.Title, { className: "modal-title text-primary h5" }, "Vos coordonn\u00E9es")),
            React.createElement(Modal.Body, { className: "px-4 pt-3" },
                React.createElement("p", null, "Veuillez v\u00E9rifier, modifier et/ou compl\u00E9ter les informations ci-dessous."),
                React.createElement("p", { className: "error" }, "* Champs obligatoires"),
                React.createElement("form", { onSubmit: handleSubmit(onSuccess, onError) },
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-5 d-flex justify-content-lg-end align-items-center text-primary mb-2 mb-lg-0' },
                            "Veuillez saisir votre adresse e-mail ",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement(RhfInputText, { rhf: {
                                    name: 'serviceId',
                                    control,
                                    rules: { required: true, validate: { valid: v => string.validEmail(v) } },
                                }, input: { maxLength: 35 }, customErrorMessage: error => {
                                    if (error.type === 'required')
                                        return 'Champ obligatoire';
                                    else if (error.type === 'valid')
                                        return 'Email no vàlid';
                                } }))),
                    React.createElement("div", { className: "modal-footer d-flex justify-content-between border-0" },
                        React.createElement("button", { className: "btn btn-tertiary btn-pill px-5", type: 'button', disabled: loading, onClick: () => { reset(); setShow(false); } }, "Fermer"),
                        React.createElement("button", { className: "btn btn-secondary btn-pill px-5", disabled: loading || !isValid || !isDirty, type: 'submit', onClick: () => { clearErrors(); } }, "Enregistrer"),
                        loading && React.createElement(Spinner, { animation: 'border', size: 'sm', className: 'text-primary ' })))))));
};
