var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Modal, Spinner } from "@isyc-react/design/bootstrap";
import React from "react";
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { RhfInputText } from '@isyc-react/form/modules/input/text';
import { useForm } from '@isyc-react/form/modules/rhf';
import { CountryAlpha2Code } from "@isyc-react/types";
import { useMutationTaxResident } from "common/queries/mutation-taxResident-create";
import { RhfInputRadioList } from "@isyc-react/form/modules/input/option";
import { countriesCombo } from "common/combo/countries";
import { RhfInputSelect } from "@isyc-react/form/modules/input/select";
import { useMutationUSPerson } from "common/queries/mutation-usperson";
export const ResidenceFiscaleModal = props => {
    const [show, setShow] = React.useState(false);
    const [deleteTax, setDeleteTax] = React.useState(false);
    const { participant, taxResidentList, refrest, setUsPerson } = props;
    const { t } = useTranslation(namespaces.common);
    const [mutationTaxResident, { loading }] = useMutationTaxResident();
    const [mutationUSPerson] = useMutationUSPerson();
    let sdsa = [];
    taxResidentList.forEach((tax, i) => {
        if (i < 3) {
            sdsa.push({
                taxResident: tax,
                comboOptionTax: tax.country != null ? countriesCombo.filter((country) => country.value === tax.country)[0] : null
            });
        }
    });
    const [taxResidentFormList, setTaxResidentFormList] = React.useState(sdsa);
    const hasOtherTaxResident = taxResidentList.length > 1 ? true : false;
    const hasOtherTaxResident2 = taxResidentList.length > 2 ? true : false;
    const { control, handleSubmit, getValues, setValue, reset, clearErrors, trigger, formState: { isValid } } = useForm({
        mode: 'all',
        defaultValues: {
            taxResidents: taxResidentFormList,
            hasOtherTaxResident: {
                id: hasOtherTaxResident ? 'true' : 'false',
                checked: hasOtherTaxResident
            },
            hasOtherTaxResident2: {
                id: hasOtherTaxResident2 ? 'true' : 'false',
                checked: hasOtherTaxResident2
            },
            isUsaPerson: {
                id: participant.person.resident ? 'true' : 'false',
                checked: false
            }
        }
    });
    React.useEffect(() => { trigger("taxResidents"); }, [taxResidentFormList]);
    const cleanTaxResident = () => __awaiter(void 0, void 0, void 0, function* () {
        let sdsa = getValues("taxResidents");
        sdsa.pop();
        clearErrors("taxResidents");
        setValue("taxResidents", sdsa);
        setTaxResidentFormList(getValues("taxResidents"));
    });
    const addTaxResident = () => {
        if (getValues("taxResidents").length < 3) {
            let sdsa = getValues("taxResidents");
            sdsa.push({
                taxResident: {
                    country: CountryAlpha2Code.UNDEFINED,
                    id: 0,
                    locality: ""
                },
                comboOptionTax: null
            });
            setTaxResidentFormList(sdsa);
            setValue("taxResidents", sdsa);
        }
        setTaxResidentFormList(getValues("taxResidents"));
    };
    const onSuccess = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        let taxResidentFinal = [];
        values.taxResidents.map((tax, i) => {
            if (i < 3 && tax.taxResident != null && tax.taxResident.locality && tax.comboOptionTax) {
                taxResidentFinal[i] = Object.assign(Object.assign({}, tax.taxResident), { country: tax.comboOptionTax && CountryAlpha2Code[tax.comboOptionTax.value] });
            }
        });
        yield mutationTaxResident({
            variables: {
                taxResident: taxResidentFinal,
                participantId: (_a = participant.id) !== null && _a !== void 0 ? _a : 0
            }
        }).then((data) => __awaiter(void 0, void 0, void 0, function* () {
            var _b;
            yield mutationUSPerson({
                variables: {
                    isUSPerson: values.isUsaPerson.id == "true" ? true : false,
                    personId: participant.person.id
                }
            }).then(() => setUsPerson(values.isUsaPerson.id == "true" ? true : false));
            setShow(false);
            clearErrors();
            let sdsa = [];
            (_b = data.data) === null || _b === void 0 ? void 0 : _b.create_tax_resident.forEach((tax, i) => {
                if (i < 3) {
                    sdsa.push({
                        taxResident: tax,
                        comboOptionTax: tax.country != null ? countriesCombo.filter((country) => country.value === tax.country)[0] : null
                    });
                }
            });
            reset({
                taxResidents: sdsa,
                hasOtherTaxResident: {
                    id: hasOtherTaxResident ? 'true' : 'false',
                    checked: hasOtherTaxResident
                },
                hasOtherTaxResident2: {
                    id: hasOtherTaxResident2 ? 'true' : 'false',
                    checked: hasOtherTaxResident2
                },
                isUsaPerson: {
                    id: values.isUsaPerson.id == "true" ? 'true' : 'false',
                    checked: false
                }
            });
            refrest && refrest();
        }));
    });
    const onError = value => {
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "btn btn-tertiary btn-lg btn-pill", onClick: () => {
                setShow(true);
            } },
            " ",
            t('edit')),
        React.createElement(Modal, { size: "xl", centered: true, show: show, onShow: () => { trigger(); setDeleteTax(false); }, onHide: () => { setShow(false); reset(); }, onClose: () => { setShow(false); reset(); } },
            React.createElement(Modal.Header, { closeButton: true, className: "bg-white border-0" },
                React.createElement(Modal.Title, { className: "modal-title text-primary h5" }, "Votre/vos r\u00E9sidence(s) fiscale(s)")),
            React.createElement(Modal.Body, { className: "px-4 pt-3" },
                React.createElement("form", { onSubmit: handleSubmit(onSuccess, onError) },
                    React.createElement("p", { className: "mb-2" }, "La Directive sur l'\u00E9change automatique et obligatoire d'information dans le domaine fiscal (Directive 2014/107/UE) oblige d\u00E9sormais les \u00E9tablissements bancaires \u00E0 transmettre \u00E0 l'administration fiscale fran\u00E7aise (DGFIP), les informations concernant les encours d\u00E9tenus et les revenus per\u00E7us par les titulaires de compte lorsqu'ils sont r\u00E9sidents dans un autre pays."),
                    React.createElement("p", { className: "error" }, "* Champs obligatoires"),
                    React.createElement("div", { className: "row mb-3" },
                        React.createElement("div", { className: "col-lg-4 d-flex justify-content-lg-end align-items-center text-primary mb-2 mb-lg-0" },
                            "Votre pays de r\u00E9sidence fiscale ",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("div", { className: "col-lg-6 text-primary" },
                            React.createElement(RhfInputSelect, { rhf: {
                                    control, name: `taxResidents.0.comboOptionTax`, rules: {
                                        required: true, validate: {
                                            noRepeat: value => {
                                                var _a, _b, _c;
                                                return value && value["value"]
                                                    && ((getValues().taxResidents.length == 3 && getValues().taxResidents[2] && value["value"] != ((_a = getValues().taxResidents[2].comboOptionTax) === null || _a === void 0 ? void 0 : _a.value)
                                                        && getValues().taxResidents[1] && value["value"] != ((_b = getValues().taxResidents[1].comboOptionTax) === null || _b === void 0 ? void 0 : _b.value)) ||
                                                        (getValues().taxResidents.length == 2 && getValues().taxResidents[1] && value["value"] != ((_c = getValues().taxResidents[1].comboOptionTax) === null || _c === void 0 ? void 0 : _c.value))
                                                        || getValues().taxResidents.length == 1);
                                            }
                                        }
                                    }
                                }, input: { options: countriesCombo, isSearchable: true, isClearable: true }, customErrorMessage: error => {
                                    if (error.type === 'noRepeat')
                                        return 'Vous ne pouvez pas choisir';
                                    else
                                        return "Champ obligatoire";
                                } }))),
                    React.createElement("div", { className: "row mb-3" },
                        React.createElement("div", { className: "col-lg-4 d-flex justify-content-lg-end align-items-center  text-primary mb-2 mb-lg-0" },
                            "Votre ville de r\u00E9sidence fiscale ",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("div", { className: "col-lg-6 text-primary" },
                            React.createElement(RhfInputText, { rhf: {
                                    control, name: 'taxResidents.0.taxResident.locality', rules: {
                                        required: true
                                    }
                                }, input: { maxLength: 35 }, transform: value => value.replace(/[^a-zA-Z0-9'()+,-.: ]/g, ''), customErrorMessage: error => {
                                    if (error.type === 'noRepeat')
                                        return 'Vous ne pouvez pas choisir';
                                    else
                                        return "Champ obligatoire";
                                } }))),
                    React.createElement("div", { className: "row mb-3 " },
                        React.createElement("div", { className: "col-lg-4 d-flex justify-content-lg-end align-items-center  text-primary mb-2 mb-lg-0" }),
                        React.createElement("div", { className: "d-none" }),
                        getValues().taxResidents.length < 2 && React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "col-auto" },
                                React.createElement("a", { href: "#", onClick: () => { addTaxResident(); } }, "Ajouter un 2\u00E8me pays de r\u00E9sidence fiscale ?"))),
                        getValues().taxResidents.length >= 2 &&
                            React.createElement("div", { className: "col-auto" },
                                "Votre 2\u00E8me r\u00E9sidence fiscale",
                                getValues().taxResidents.length == 2 &&
                                    React.createElement("i", { "data-bs-toggle": "tooltip", "aria-label": "Supprimer les champs", title: "Supprimer les champs", className: "mdi mdi-delete text-secondary font-size-24 cursor-pointer", onClick: () => { cleanTaxResident(); } }))),
                    getValues().taxResidents.map((tax, i) => (React.createElement(React.Fragment, null, i != 0 &&
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "row mb-3" },
                                React.createElement("div", { className: "col-lg-4 d-flex justify-content-lg-end align-items-center text-primary mb-2 mb-lg-0" },
                                    "Votre ",
                                    i == 1 ? "2ème" : "3ème",
                                    " pays de r\u00E9sidence fiscale ",
                                    React.createElement("span", { className: "required ms-1" }, "*")),
                                React.createElement("div", { className: "col-lg-6 text-primary" },
                                    React.createElement(RhfInputSelect, { rhf: {
                                            control, name: `taxResidents.${i}.comboOptionTax`, rules: {
                                                required: true, validate: {
                                                    noRepeat: value => {
                                                        var _a, _b, _c;
                                                        return value && value["value"]
                                                            && value["value"] != ((_a = getValues().taxResidents[0].comboOptionTax) === null || _a === void 0 ? void 0 : _a.value)
                                                            && ((getValues().taxResidents.length == 3 && i === 1 && getValues().taxResidents[2] && value["value"] != ((_b = getValues().taxResidents[2].comboOptionTax) === null || _b === void 0 ? void 0 : _b.value)) ||
                                                                (getValues().taxResidents.length == 3 && i === 2 && getValues().taxResidents[1] && value["value"] != ((_c = getValues().taxResidents[1].comboOptionTax) === null || _c === void 0 ? void 0 : _c.value)) ||
                                                                (getValues().taxResidents.length == 2));
                                                    }
                                                }
                                            }
                                        }, input: { options: countriesCombo, isSearchable: true, isClearable: true }, customErrorMessage: error => {
                                            if (error.type === 'noRepeat')
                                                return 'Vous ne pouvez pas choisir';
                                            else
                                                return "Champ obligatoire";
                                        } }))),
                            React.createElement("div", { className: "row mb-3" },
                                React.createElement("div", { className: "col-lg-4 d-flex justify-content-lg-end align-items-center  text-primary mb-2 mb-lg-0" },
                                    "Votre ",
                                    i == 1 ? "2ème" : "3ème",
                                    " ville de r\u00E9sidence fiscale ",
                                    React.createElement("span", { className: "required ms-1" }, "*")),
                                React.createElement("div", { className: "col-lg-6 text-primary" },
                                    React.createElement(RhfInputText, { rhf: {
                                            control, name: `taxResidents.${i}.taxResident.locality`, rules: {
                                                required: true,
                                            }
                                        }, input: { maxLength: 35 }, transform: value => value.replace(/[^a-zA-Z0-9'()+,-.: ]/g, ''), customErrorMessage: error => {
                                            if (error.type === 'required')
                                                return 'Champ obligatoire';
                                            else if (error.type === 'valid')
                                                return 'Caractère non valide';
                                        } }))),
                            i < 2 &&
                                React.createElement("div", { className: "row mb-3 " },
                                    React.createElement("div", { className: "col-lg-4 d-flex justify-content-lg-end align-items-center  text-primary mb-2 mb-lg-0" }),
                                    getValues().taxResidents.length < 3 &&
                                        React.createElement("div", { className: "col-auto" },
                                            React.createElement("a", { href: "#", onClick: () => { addTaxResident(); } },
                                                "Ajouter un ",
                                                i == 1 && "3ème",
                                                " pays de r\u00E9sidence fiscale ?")),
                                    getValues().taxResidents.length == 3 &&
                                        React.createElement("div", { className: "col-auto" },
                                            "Votre ",
                                            i == 1 && "3ème",
                                            " r\u00E9sidence fiscale",
                                            React.createElement("i", { "data-bs-toggle": "tooltip", "aria-label": "Supprimer les champs", title: "Supprimer les champs", className: "ml-3 mdi mdi-delete text-secondary font-size-24 cursor-pointer", onClick: () => { cleanTaxResident(); } }))))))),
                    React.createElement("div", { className: "row mb-3" },
                        React.createElement("div", { className: "col-lg-4 d-flex justify-content-lg-end align-items-center  text-primary mb-2 mb-lg-0" },
                            "\u00C9tes-vous contribuable am\u00E9ricain US Person ? ",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("div", { className: "col-lg-6 d-flex align-items-center" },
                            React.createElement(RhfInputRadioList, { rhf: { control, name: 'isUsaPerson', rules: { required: true } }, input: {
                                    items: [
                                        { id: 'true', checked: false },
                                        { id: 'false', checked: false },
                                    ],
                                    labels: [
                                        React.createElement("div", { className: "form-check form-check-inline mb-0" }, "Oui"),
                                        React.createElement("div", { className: "form-check form-check-inline mb-0" }, "Non")
                                    ]
                                } }))),
                    React.createElement("div", { className: "modal-footer d-flex justify-content-between border-0" },
                        React.createElement("button", { className: "btn btn-tertiary btn-pill px-5", disabled: loading, type: 'button', onClick: () => { setShow(false); reset(); } }, "Fermer"),
                        React.createElement("button", { className: "btn btn-secondary btn-pill px-5", disabled: loading || !isValid, type: 'submit' }, "Enregistrer"),
                        loading && React.createElement(Spinner, { animation: 'border', size: 'sm', className: 'text-primary ml-3' })))))));
};
