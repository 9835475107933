export const getHouseholdIncomeEnum = (value) => {
    switch (value) {
        case "E100":
            return "E100" /* HouseholdIncomeEnum.E100 */;
        case "E200":
            return "E200" /* HouseholdIncomeEnum.E200 */;
        case "E300":
            return "E300" /* HouseholdIncomeEnum.E300 */;
        case "E400":
            return "E400" /* HouseholdIncomeEnum.E400 */;
        case "E500":
            return "E500" /* HouseholdIncomeEnum.E500 */;
        case "E600":
            return "E600" /* HouseholdIncomeEnum.E600 */;
        case "E700":
            return "E700" /* HouseholdIncomeEnum.E700 */;
        case "E800":
            return "E800" /* HouseholdIncomeEnum.E800 */;
        default:
            return "UNDEFINED" /* HouseholdIncomeEnum.UNDEFINED */;
    }
};
