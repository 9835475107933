import { gql, useQuery } from '@apollo/client';
import { COMBO_READ_FIELDS } from '../apollo';
// FIXME THIS QUERY ALREADY IS IN OC-API LIB -> use it from there
export const GET_COMBO_READ = gql `
  ${COMBO_READ_FIELDS}
  query getComboRead($comboCode: String) {
    combo_read_byCode(comboCode: $comboCode) {
      ...ComboReadFields
    }
  }
`;
export const useQueryComboRead = (options) => {
    return useQuery(GET_COMBO_READ, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first' }, options));
};
