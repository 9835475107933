import React from 'react';
import erreur from 'assets/img/error.jpg';
import { MainLayout } from 'layout/example';
export const ErrorScene = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(MainLayout, { hideButtons: true, hideFooter: true },
            React.createElement("div", { className: "card p-4 bg-white rounded-lg shadow mt-3 mt-lg-5" },
                React.createElement("div", { className: "card-body p-2" },
                    React.createElement("div", { className: "row p-5 d-flex justify-content-center" },
                        React.createElement("div", { className: "col-auto" },
                            React.createElement("img", { src: erreur, className: "img-fluid", width: "500" })),
                        React.createElement("div", { className: "col-auto text-center" },
                            React.createElement("p", null),
                            React.createElement("h1", { className: "display-1 text-muted fw-bold text-muted" }, "Erreur"),
                            React.createElement("p", null),
                            React.createElement("p", null, "La page que vous cherchez est introuvable."))))))));
};
