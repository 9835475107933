import React from 'react';
import { useAuthContext } from "@isyc-react/auth/modules/context";
import { InformationsProfessionalModal } from './informations-modal.component';
import { useQueryComboRead } from 'common/queries/combo-read';
import { useQueryInformationProfessional } from 'common/queries/query-information';
import { householdIncomeEnumCombo } from 'common/combo/householdIncome';
import { wealthCombo } from 'common/combo/wealth';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
export const PersonalInformationsComponent = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { user } = useAuthContext();
    const { t } = useTranslation(namespaces.common);
    const [info, setInfo] = React.useState(null);
    const { data: dataCombo } = useQueryComboRead({ variables: { comboCode: 'CNO' } });
    const { data: informationsData, refetch } = useQueryInformationProfessional({ variables: { personId: (_b = (_a = user === null || user === void 0 ? void 0 : user.person) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0 } });
    const comboPersonLabor = React.useMemo(() => dataCombo === null || dataCombo === void 0 ? void 0 : dataCombo.combo_read_byCode.map(cr => ({ value: cr.value.toString(), label: cr.label })), [dataCombo]);
    const { data: dataComboCodeNaf } = useQueryComboRead({ variables: { comboCode: 'CODE_NAF' } });
    const comboCodeNaf = React.useMemo(() => dataComboCodeNaf === null || dataComboCodeNaf === void 0 ? void 0 : dataComboCodeNaf.combo_read_byCode.map(cr => ({ value: cr.value.toString(), label: cr.label })), [dataComboCodeNaf]);
    React.useEffect(() => (informationsData === null || informationsData === void 0 ? void 0 : informationsData.load_information_professional_by_person_id) && setInfo(informationsData.load_information_professional_by_person_id), [informationsData]);
    return (React.createElement(React.Fragment, null, info && comboPersonLabor && comboCodeNaf &&
        React.createElement("div", { className: "card p-4 bg-white rounded-lg shadow mt-3 mt-lg-5" },
            React.createElement("div", { className: "card-header bg-white border-0" },
                React.createElement("h2", null, "Vos informations professionnelles")),
            React.createElement("div", { className: "card-body" },
                React.createElement("div", { className: "row mb-2 mb-md-1" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Situation profesionnelle :"),
                    props.errorDtos.filter((t) => t.property === 'cno').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        :
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, dataCombo && ((_c = dataCombo === null || dataCombo === void 0 ? void 0 : dataCombo.combo_read_byCode.find(line => line.value === info.cno)) === null || _c === void 0 ? void 0 : _c.label))),
                React.createElement("div", { className: "row mb-2 mb-md-1" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Employeur :"),
                    props.errorDtos.filter((t) => t.property === 'workCompanyName').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        :
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, info === null || info === void 0 ? void 0 : info.workCompanyName)),
                React.createElement("div", { className: "row mb-2 mb-md-1" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Secteur d'activit\u00E9 :"),
                    props.errorDtos.filter((t) => t.property === 'activitySectorBforBank').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        :
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, info.activitySectorBforBank && ((_d = comboCodeNaf.find(line => line.value === (info === null || info === void 0 ? void 0 : info.activitySectorBforBank.toString()))) === null || _d === void 0 ? void 0 : _d.label))),
                React.createElement("div", { className: "row mb-2 mb-md-1" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Intitul\u00E9 du poste :"),
                    props.errorDtos.filter((t) => t.property === 'jobTitle').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        :
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, info === null || info === void 0 ? void 0 : info.jobTitle)),
                React.createElement("div", { className: "row mb-2 mb-md-1" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Revenus annuels net du foyer :"),
                    props.errorDtos.filter((t) => t.property === 'wealth').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        :
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, info.wealth && ((_e = wealthCombo.find(c => c.value === info.wealth.toString())) === null || _e === void 0 ? void 0 : _e.label))),
                React.createElement("div", { className: "row mb-2 mb-md-1" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Patrimoine net global :"),
                    props.errorDtos.filter((t) => t.property === 'householdIncome').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        :
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, info.householdIncome && ((_f = householdIncomeEnumCombo.find(c => c.value === info.householdIncome.toString())) === null || _f === void 0 ? void 0 : _f.label)))),
            React.createElement("div", { className: "card-footer bg-white border-0 d-flex justify-content-end" },
                React.createElement(InformationsProfessionalModal, { errorBforBank: props.errorDtos, comboPersonLabor: comboPersonLabor, info: info, personId: (_h = (_g = user === null || user === void 0 ? void 0 : user.person) === null || _g === void 0 ? void 0 : _g.id) !== null && _h !== void 0 ? _h : 0, refrest: () => { refetch(); props.validator(); }, comboCodeNaf: comboCodeNaf })))));
};
