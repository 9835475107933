import { gql, useQuery } from '@apollo/client';
import { ADDRESS_FIELDS } from '../apollo';
// FIXME THIS QUERY ALREADY IS IN OC-API LIB -> use it from there
export const GET_MAIN_ADDRESS_READ = gql `
  ${ADDRESS_FIELDS}
  query getMainAddressRead($ownerId: Long!,$ownerTypeId: Int) {
    load_main_address_by_owner_id(ownerId: $ownerId,ownerTypeId:$ownerTypeId) {
      ...AddressFields
    }
  }
`;
export const useQueryMainAddress = (options) => {
    return useQuery(GET_MAIN_ADDRESS_READ, Object.assign({ errorPolicy: 'ignore', fetchPolicy: 'cache-first' }, options));
};
