import { Button, Modal, Spinner } from "@isyc-react/design/bootstrap";
import { CountryAlpha2Code } from "@isyc-react/types/core/base/country";
import React from "react";
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { RhfInputText } from '@isyc-react/form/modules/input/text';
import { useForm } from '@isyc-react/form/modules/rhf';
import { useMutationAddress } from "common/queries/mutation-address";
import { RhfInputSelect } from "@isyc-react/form/modules/input/select";
import { countriesCombo } from "common/combo/countries";
export const AddressModal = props => {
    var _a;
    const [show, setShow] = React.useState(false);
    const { mainAddress, ownerId, refrest } = props;
    const { t } = useTranslation(namespaces.common);
    const [mutationMainAddress, { loading }] = useMutationAddress();
    const { control, handleSubmit, getValues, formState: { isValid, isDirty, errors }, reset, trigger, clearErrors } = useForm({
        mode: 'onTouched',
        defaultValues: Object.assign(Object.assign({}, mainAddress), { comboOptionAddress: mainAddress.countryCode ? countriesCombo.filter((country) => country.value === mainAddress.countryCode)[0] : null }),
    });
    const [codePostalRequired, setCodePostalRequired] = React.useState(((_a = getValues("comboOptionAddress")) === null || _a === void 0 ? void 0 : _a.value) == 'FR');
    const onSuccess = (value) => {
        if (ownerId != 0)
            mutationMainAddress({
                variables: {
                    address: {
                        name: value.name,
                        additionalNameInformation1: value.additionalNameInformation1,
                        locality: value.locality,
                        postalCode: value.postalCode,
                        countryCode: value.comboOptionAddress && CountryAlpha2Code[value.comboOptionAddress.value]
                    }, personId: ownerId
                }
            }).then((data) => {
                var _a, _b;
                clearErrors(["name", "locality", "postalCode", "countryCode"]);
                setShow(false);
                reset(Object.assign(Object.assign({}, (_a = data.data) === null || _a === void 0 ? void 0 : _a.person_updateMainAddressBforBank), { comboOptionAddress: ((_b = data.data) === null || _b === void 0 ? void 0 : _b.person_updateMainAddressBforBank.countryCode) ? countriesCombo.filter((country) => { var _a; return country.value === ((_a = data.data) === null || _a === void 0 ? void 0 : _a.person_updateMainAddressBforBank.countryCode); })[0] : null }));
                refrest && refrest();
            });
    };
    const onError = value => {
        console.log("error");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "btn btn-tertiary btn-lg btn-pill", onClick: () => {
                setShow(true);
            } },
            " ",
            t('edit')),
        React.createElement(Modal, { size: "xl", centered: true, show: show, onShow: () => { trigger(); }, onHide: () => { setShow(false); reset(); }, onClose: () => { setShow(false); reset(); } },
            React.createElement(Modal.Header, { closeButton: true, className: "bg-white border-0" },
                React.createElement(Modal.Title, { className: "modal-title text-primary h5" }, t('address'))),
            React.createElement(Modal.Body, { className: "px-4 pt-3" },
                React.createElement("p", { className: "mb-2" }, "Si vous avez r\u00E9cemment d\u00E9m\u00E9nag\u00E9, vous devez modifier les champs ci-dessous. Si non, veuillez valider et passer \u00E0 l'\u00E9tape suivante."),
                React.createElement("p", { className: "error" }, "* Champs obligatoires"),
                React.createElement("form", { onSubmit: handleSubmit(onSuccess, onError) },
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-4 d-flex justify-content-lg-end align-items-center  text-primary mb-2 mb-lg-0' },
                            "Adresse",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement(RhfInputText, { rhf: {
                                    control, name: 'name', rules: {
                                        required: true,
                                    }
                                }, input: { maxLength: 35 }, transform: value => value.replace(/[^a-zA-Z0-9'()+,-./:?& ]/g, ''), customErrorMessage: error => {
                                    if (error.type === 'required')
                                        return 'Champ obligatoire';
                                } }))),
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-4 d-flex justify-content-lg-end align-items-center  text-primary mb-2 mb-lg-0' }, "Compl\u00E9ment d'adresse"),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement(RhfInputText, { rhf: {
                                    control, name: 'additionalNameInformation1', rules: {
                                        required: false,
                                    }
                                }, input: { maxLength: 35 }, transform: value => value.replace(/[^a-zA-Z0-9'()+,-./:?& ]/g, ''), customErrorMessage: error => {
                                    if (error.type === 'required')
                                        return 'Champ obligatoire';
                                    else if (error.type === 'valid')
                                        return 'Caractère non valide';
                                } }))),
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-4 d-flex justify-content-lg-end align-items-center  text-primary mb-2 mb-lg-0' },
                            "Code postal ",
                            codePostalRequired && React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement(RhfInputText, { rhf: {
                                    control, name: 'postalCode', rules: {
                                        required: codePostalRequired,
                                        validate: {
                                            valid: (value) => !value || /^[0-9\b]+$/.test(value),
                                        },
                                    },
                                }, input: { maxLength: 35 }, customErrorMessage: error => {
                                    if (error.type === 'required')
                                        return 'Champ obligatoire';
                                    else if (error.type === 'valid')
                                        return 'Caractère non valide';
                                } }))),
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-4 d-flex justify-content-lg-end align-items-center  text-primary mb-2 mb-lg-0' },
                            "Ville",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement(RhfInputText, { rhf: {
                                    control, name: 'locality', rules: {
                                        required: true,
                                    }
                                }, input: { maxLength: 35 }, transform: value => value.replace(/[^a-zA-Z0-9'()+,-./:?& ]/g, ''), customErrorMessage: error => {
                                    if (error.type === 'required')
                                        return 'Champ obligatoire';
                                    else if (error.type === 'valid')
                                        return 'Caractère non valide';
                                } }))),
                    React.createElement("div", { className: 'row mb-3' },
                        React.createElement("label", { className: 'col-lg-4 d-flex justify-content-lg-end align-items-center  text-primary mb-2 mb-lg-0' },
                            "Pays",
                            React.createElement("span", { className: "required ms-1" }, "*")),
                        React.createElement("label", { className: 'col-lg-6 text-primary' },
                            React.createElement(RhfInputSelect, { rhf: {
                                    control, name: 'comboOptionAddress', rules: {
                                        required: true, onChange(event) {
                                            var _a;
                                            setCodePostalRequired(((_a = getValues("comboOptionAddress")) === null || _a === void 0 ? void 0 : _a.value) == 'FR');
                                        },
                                    }
                                }, input: { options: countriesCombo, isSearchable: true }, customErrorMessage: "Champ obligatoire" }))),
                    React.createElement("div", { className: "modal-footer d-flex justify-content-between border-0" },
                        React.createElement("button", { className: "btn btn-tertiary btn-pill px-5", type: 'button', disabled: loading, onClick: () => { reset(); setShow(false); } }, "Fermer"),
                        React.createElement("button", { className: "btn btn-secondary btn-pill px-5", disabled: loading || !isValid || !isDirty, type: 'submit' }, "Enregistrer"),
                        loading && React.createElement(Spinner, { animation: 'border', size: 'sm', className: 'text-primary ml-3' })))))));
};
