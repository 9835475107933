export const getWealthEnum = (value) => {
    switch (value) {
        case "E100":
            return "E100" /* WealthEnum.E100 */;
        case "E200":
            return "E200" /* WealthEnum.E200 */;
        case "E300":
            return "E300" /* WealthEnum.E300 */;
        case "E400":
            return "E400" /* WealthEnum.E400 */;
        case "E500":
            return "E500" /* WealthEnum.E500 */;
        case "E600":
            return "E600" /* WealthEnum.E600 */;
        case "E700":
            return "E700" /* WealthEnum.E700 */;
        case "E800":
            return "E800" /* WealthEnum.E800 */;
        default:
            return "UNDEFINED" /* WealthEnum.UNDEFINED */;
    }
};
