import { OverlayTrigger, Popover, Spinner } from '@isyc-react/design/bootstrap';
import React from 'react';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { getDocRequest } from '@isyc-react/form-file/modules/doc-management/deprecated/doc-request-v2/doc-request-context/doc-request.query';
import { countriesI18nExpanded } from '@isyc-react/form/modules/countries/expanded/countries-i18n-expanded';
import { DocumentalReqComponent } from 'pods/documental/documental-req.component';
import { DocRequestProvider } from '@isyc-react/form-file/modules/doc-management/deprecated/doc-request-v2/doc-request-context';
import { useDossierReadByPersonQuery } from '@isyc-react/oc-api';
import { useDocValidationGroupByRelatedQuery } from '@isyc-react/form-file/modules/doc-management/deprecated/doc-request-v2/doc-group/doc-group.query';
import { DocumentalComponent } from 'pods/documental/documental.component';
import { useQueryMainAddress } from 'common/queries/query-mainAddress-read';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { AddressModal } from './address-modal.component';
export const MainAddressComponent = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t } = useTranslation(namespaces.common);
    const [mainAddress, setMainAddress] = React.useState(null);
    const { user } = useAuthContext();
    const [hasDocRequest, setDocRequest] = React.useState(null);
    const [docRequestList, setDocRequestList] = React.useState([]);
    const { data: dossiersData } = useDossierReadByPersonQuery({ variables: { personId: (_b = (_a = user === null || user === void 0 ? void 0 : user.person) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0 } });
    const { data: docs, refetch: refetchDocs } = useDocValidationGroupByRelatedQuery({ variables: { relatedId: (_c = dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson[0].id) !== null && _c !== void 0 ? _c : 0, relatedObjectTypeId: 1415 } });
    const { data: mainAddressData, refetch } = useQueryMainAddress({ variables: { ownerId: (_e = (_d = user === null || user === void 0 ? void 0 : user.person) === null || _d === void 0 ? void 0 : _d.id) !== null && _e !== void 0 ? _e : 0, ownerTypeId: 1022 } });
    React.useEffect(() => {
        getDocRequest()
            .then(({ data }) => {
            if (data.docRequestPending.length > 0) {
                setDocRequestList(data.docRequestPending);
                setDocRequest(true);
            }
            else if (data.docRequestCompleted.length > 0) {
                setDocRequest(false);
            }
        }).catch(e => {
            console.log(e);
            if (e.code == "ERR_BAD_REQUEST") {
                setDocRequest(false);
            }
            else
                console.log('[Doc Request] Error getting documental request data', e);
        });
    }, [mainAddress]);
    React.useEffect(() => {
        if (mainAddressData === null || mainAddressData === void 0 ? void 0 : mainAddressData.load_main_address_by_owner_id) {
            refetchDocs().then(() => setMainAddress(mainAddressData === null || mainAddressData === void 0 ? void 0 : mainAddressData.load_main_address_by_owner_id));
        }
    }, [mainAddressData]);
    const popoverFocus = (React.createElement(Popover, { id: "popover-trigger-focus", title: "Pour les autres documents accept\u00E9es vous pouvez charger :" },
        React.createElement(Popover.Header, { as: "h3" }, "Pour les autres documents accept\u00E9es vous pouvez charger :"),
        React.createElement(Popover.Body, null,
            React.createElement("ul", { className: "list-group" },
                React.createElement("li", { className: "list-group-item border-0" }, "Taxe d'habitation de moins d'un an"),
                React.createElement("li", { className: "list-group-item border-0" }, "Attestation d'assurance habitation de moins de 3 mois"),
                React.createElement("li", { className: "list-group-item border-0" }, "Quittance de loyer (par un organisme reconnu : HLM/CIAS) de moins de 3 mois")))));
    return (React.createElement(React.Fragment, null, mainAddress && dossiersData ? React.createElement(React.Fragment, null,
        React.createElement("div", { className: "card p-4 bg-white rounded-lg shadow mt-3 mt-lg-5" },
            React.createElement("div", { className: "card-header bg-white border-0" },
                React.createElement("h2", null, "Votre adresse")),
            React.createElement("div", { className: "card-body" },
                React.createElement("div", { className: "row mb-2" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Adresse :"),
                    props.errorDtos.filter((t) => t.property === 'name').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        : React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.name)),
                React.createElement("div", { className: "row mb-2" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Compl\u00E9ment d'adresse :"),
                    React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.additionalNameInformation1)),
                React.createElement("div", { className: "row mb-2" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Code postal :"),
                    props.errorDtos.filter((t) => t.property === 'postalCode').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        : React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.postalCode)),
                React.createElement("div", { className: "row mb-2" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Ville :"),
                    props.errorDtos.filter((t) => t.property === 'locality').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        : React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.locality)),
                React.createElement("div", { className: "row mb-2" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Pays :"),
                    props.errorDtos.filter((t) => t.property === 'countryCode').length != 0 ?
                        React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire")
                        :
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, mainAddress && (mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.countryCode) && countriesI18nExpanded.getName(mainAddress === null || mainAddress === void 0 ? void 0 : mainAddress.countryCode, 'fr'))),
                hasDocRequest != null ? React.createElement(React.Fragment, null, !hasDocRequest ? React.createElement(React.Fragment, null, (docs === null || docs === void 0 ? void 0 : docs.documentalValidationGroupsRequired_read_byRelated) != null ?
                    React.createElement(React.Fragment, null,
                        React.createElement(DocumentalComponent, { dossierID: dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson[0].id, mainAddress: mainAddress, doc: docs === null || docs === void 0 ? void 0 : docs.documentalValidationGroupsRequired_read_byRelated, onSuccess: () => { refetch(); props.validator(); } }))
                    :
                        React.createElement("div", { className: 'd-flex justify-content-center align-items-center mb-4', style: { height: '750px' } },
                            React.createElement(Spinner, { animation: 'border' })))
                    : React.createElement(React.Fragment, null, (dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson) != null ?
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'card-footer bg-white border-0 d-flex justify-content-end' },
                                React.createElement(AddressModal, { ownerId: user && user.person ? (_f = user.person) === null || _f === void 0 ? void 0 : _f.id : 0, mainAddress: mainAddress, refrest: () => { refetch(); props.validator(); } })),
                            React.createElement("div", { className: "row mt-5 text-center" },
                                React.createElement("p", null,
                                    "Vous avez chang\u00E9 d\u2019adresse, veuillez t\u00E9l\u00E9charger votre pi\u00E8ce justificative :",
                                    React.createElement(OverlayTrigger, { trigger: "click", rootClose: true, placement: "right", overlay: popoverFocus },
                                        React.createElement("i", { className: "mdi mdi-information text-secondary font-size-24 cursor-help ms-1 align-middle" })),
                                    "                                                "),
                                React.createElement("div", { className: " mb-0 mb-5 col-md-6 mx-auto align-items-center justify-content-center p-4" }, (dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson.length) > 0 &&
                                    React.createElement(DocRequestProvider, { userPersonId: user && user.person ? (_g = user.person) === null || _g === void 0 ? void 0 : _g.id : 0, dossierId: dossiersData === null || dossiersData === void 0 ? void 0 : dossiersData.dossier_readByPerson[0].id },
                                        React.createElement(DocumentalReqComponent, { docRequestList: docRequestList, onSuccess: () => { refetch(); props.validator(); } }))))) :
                        React.createElement("div", { className: 'd-flex justify-content-center align-items-center mb-4', style: { height: '750px' } },
                            React.createElement(Spinner, { animation: 'border' }))))
                    :
                        React.createElement("div", { className: 'd-flex align-items-center justify-content-center m-5' },
                            React.createElement(Spinner, { animation: 'border' })))))
        :
            React.createElement("div", { className: 'd-flex align-items-center justify-content-center m-5' },
                React.createElement(Spinner, { animation: 'border' }))));
};
