import React from 'react';
import { ResidenceFiscaleModal } from './residenceFiscale-modal.component';
import { useQueryTaxResident } from 'common/queries/query-taxResident';
import { Spinner } from '@isyc-react/design/bootstrap';
import { countriesCombo } from 'common/combo/countries';
import { Redirect } from 'react-router-dom';
import { routes } from 'core/router';
export const ResidenceFiscaleComponent = (props) => {
    const { participant } = props;
    const [usPerson, setUsPerson] = React.useState(participant.person.resident);
    const { data: taxResidencesData, refetch } = useQueryTaxResident({ variables: { participantId: participant.id } });
    return (React.createElement(React.Fragment, null, taxResidencesData ? React.createElement(React.Fragment, null, taxResidencesData.get_tax_resident_by_participant_id.length != 0 ? React.createElement(React.Fragment, null,
        React.createElement("div", { className: "card p-4 bg-white rounded-lg shadow mt-3 mt-lg-5" },
            React.createElement("div", { className: "card-header bg-white border-0" },
                React.createElement("h2", null, "Votre/vos r\u00E9sidence(s) fiscale(s)")),
            React.createElement("div", { className: "card-body" },
                taxResidencesData.get_tax_resident_by_participant_id.map((tax, i) => (React.createElement(React.Fragment, null, i < 3 && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "row mb-2 mb-md-1" },
                        React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" },
                            "Votre ",
                            i == 1 && '2ème',
                            i == 2 && '3ème',
                            "  pays de r\u00E9sidence fiscale :"),
                        props.errorDtos.filter((t) => t.property === tax.id + '.country').length != 0 ?
                            React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire") :
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, (tax === null || tax === void 0 ? void 0 : tax.country) && countriesCombo.filter((country) => country.value === (tax === null || tax === void 0 ? void 0 : tax.country))[0].label)),
                    React.createElement("div", { className: "row mb-2 mb-md-1" },
                        React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" },
                            "Votre ",
                            i == 1 && '2ème',
                            " ",
                            i == 2 && '3ème',
                            " ville de r\u00E9sidence fiscale :"),
                        props.errorDtos.filter((t) => t.property === tax.id + '.locality').length != 0 ?
                            React.createElement("div", { className: 'col-lg-6 d-flex text-danger ' }, "Champ obligatoire") :
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, tax.locality)),
                    i == 0 &&
                        React.createElement("div", { className: "row mb-2 mb-md-1" },
                            React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "Etes-vous r\u00E9sident fiscal d'un autre pays ? :"),
                            React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, taxResidencesData.get_tax_resident_by_participant_id.length > 1 ? 'Oui' : 'Non')))))),
                React.createElement("div", { className: "row mb-2 mb-md-1" },
                    React.createElement("div", { className: "col-lg-6 d-flex justify-content-lg-end text-primary" }, "\u00C9tes-vous contribuable am\u00E9ricain US Person ? :"),
                    React.createElement("div", { className: "col-lg-6 text-primary fw-bold" }, usPerson ? 'Oui' : 'Non'))),
            React.createElement("div", { className: "card-footer bg-white border-0 d-flex justify-content-end" },
                React.createElement(ResidenceFiscaleModal, { taxResidentList: taxResidencesData.get_tax_resident_by_participant_id, participant: participant, setUsPerson: (value) => setUsPerson(value), refrest: () => { refetch(); props.validator(); } })))) : React.createElement(Redirect, { to: routes.error })) :
        React.createElement("div", { className: 'd-flex align-items-center justify-content-center' },
            React.createElement(Spinner, { animation: 'border' }))));
};
