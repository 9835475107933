import { gql, useMutation } from "@apollo/client";
import { INFORMATION_PROFESSIONAL_FIELDS } from "common/apollo";
export const UPDATE_INFORMATION_PROFESSIONAL = gql `
  ${INFORMATION_PROFESSIONAL_FIELDS}
    mutation updateInformationProfessional ($Informations:InformationsProfessionalDtoInput, $personId:Long!){
        person_updateInformationProfessional(Informations:$Informations,personId:$personId){
        ...InformationProfessional 
        }
    }    
`;
export const useMutationInformationProfessional = (options) => {
    return useMutation(UPDATE_INFORMATION_PROFESSIONAL, Object.assign({ errorPolicy: 'all' }, options));
};
