import { AutoLoginLayout } from '@isyc-react/auth/modules/pods/login';
import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ErrorScene } from 'scenes';
import { switchRoutes } from '../router';
import { AutoLoginForm } from './auto-login-form.component';
export const RouterUnlogged = () => {
    const location = useLocation();
    return (React.createElement(Switch, { location: location },
        React.createElement(Route, { path: [switchRoutes.autologin] },
            React.createElement(AutoLoginLayout, null,
                React.createElement(AutoLoginForm, null))),
        React.createElement(Route, { path: '*' },
            React.createElement(ErrorScene, null))));
};
