import { gql } from '@apollo/client';
export const COMBO_READ_FIELDS = gql `
    fragment ComboReadFields on ComboEntry{
        value
        label

    }
`;
export const INFORMATION_PROFESSIONAL_FIELDS = gql `
    fragment InformationProfessional on InformationsProfessionalDto {
    wealth
    activitySectorBforBank
    jobTitle
    workCompanyName
    cno
    householdIncome
    }
`;
export const ADDRESS_FIELDS = gql `
    fragment AddressFields on AddressDto {
        name
        postalCode
        locality
        countryCode
        additionalNameInformation1  
    }

`;
export const CONTACT_FIELDS = gql `
fragment ContactFields on ContactDto {
    serviceId
}

`;
export const TAX_RESIDENT_FIELDS = gql `
    fragment TaxResidentFields on TaxResidentDto {
        locality
        country
        id
    }

`;
export const ERROR_BFORBANK_FIELDS = gql `
    fragment ErrorBforBankFields on ErrorBforBankDto {
        property
        step
    }

`;
